import React, { useState } from 'react';
import styled from 'styled-components';


/**
 * Converts a date to the number of weeks since the start of the year.
 *
 * @param {Date} date
 * @return {Number}
 */
function calculateWeek(date) {
  const MILLIS_IN_DAY = 8.64e7;
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);

  const diffDays = (date - firstDayOfYear) / MILLIS_IN_DAY;

  return Math.floor(diffDays / 7) + 1;
}

const Content = styled.div`
  font-family: Arial, sans-serif;

  max-width: 100%;
  width: 400px;

  padding-top: 50px;
  margin: 0 auto;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;

  height: 300px;
  overflow-y: scroll;

  li {
    padding: 10px;
    cursor: pointer;

    :nth-child(2n+1) {
      background-color: #e0eef1;
    }

    :nth-child(2n) {
      background-color: #f4f9f9;
    }
  }
`;

const Tickbox = styled.span`
  position: relative;
  margin-left: 50px;
  vertical-align: center;
  
  input {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -3px;
    right: -30px;
  }
`

const Input = styled.input`
  padding: 10px;

  outline: none;

  border: 1px solid black;
`;

const Link = styled.a`
  margin: 20px 50px;
  display: block;
  padding: 20px;
  text-align: center;

  color: white;
  background-color: rebeccapurple;
  font-family: "Arial", serif;

  text-decoration: none;
`;

export default function IndexPage() {
  const [room, setRoom] = useState('');
  const currentDate = new Date();

  const [showFullWeek, setShowFullWeek] = useState(false);

  const NAVIGATION_URL = `https://timetable.auckland.ac.nz/Scientia/Timetable${currentDate.getFullYear()}/Reports/Calendar.aspx`;

  const query = {
    objects: room,
    weeks: calculateWeek(currentDate),
    template: 'location_grid',
    days: `${showFullWeek ? '1-7' : currentDate.getDay()}`,
    periods: '1-32',
  };

  const commonRooms = {
    '405-326': 'MDLS Computing 5',
    '405-328': 'MDLS Computing 6',
    '405-336': 'MDLS BYOD 7',
    '405-344': 'MDLS BYOD 8',
    '260-205': 'OGGB Meeting Room',
    '206-214': 'Arts 1 Seminar',
    '206-215': 'Arts 1 Seminar',
    '206-216': 'Arts 1 Seminar',
    '253-103': '253-103',
    '253-104': '253-104',
  };

  const handleNavigation = () => {
    const queryString = '?' + Object.entries(query).map(([k, v]) => `${k}=${v}`).join('&');

    window.open(NAVIGATION_URL + queryString);
  };

  return (
    <React.Fragment>
      <Content>
        <h1>Room Booking Navigator</h1>

        <List>
          {Object.entries(commonRooms).map(([key, value]) => {
            return (<li key={key} onClick={() => setRoom(key)}>{value} ({key})</li>);
          })}
        </List>

        <Input placeholder={'Custom Room'} type="string" value={room} onChange={(e) => setRoom(e.target.value)} />
        <Tickbox>Show Full Week <input type={"checkbox"} onChange={(e) => setShowFullWeek(!showFullWeek)} /></Tickbox>
        <Link href={''} onClick={handleNavigation}>View Room: {room || '-'}</Link>
      </Content>
    </React.Fragment>
  );
}
